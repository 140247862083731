// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.tsx");
}
// REMIX HMR END

import { Center, ChakraProvider, extendTheme, Image, useColorModeValue, useToast, VStack } from "@chakra-ui/react";
import { withEmotionCache } from "@emotion/react";
import { Link, Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, useLocation, useNavigate } from "@remix-run/react";
import React, { createElement, useContext, useEffect } from "react";
import appStylesHref from "./app.css";
import { withProse } from "@nikolovlazar/chakra-ui-prose";
import { FaArrowLeft } from "react-icons/fa";
import TruthistPrimaryButton from "./components/truthist-primary-button";
import { TruthistH4 } from "./components/truthist-styles";
import { ClientStyleContext, ServerStyleContext } from "./context";
import TruthistSecondaryButton from "./components/truthist-secondary-button";
const config = {
  initialColorMode: "system"
};
const theme = extendTheme({
  config,
  fonts: {
    heading: `'Space Grotesk', sans-serif`,
    body: `'Space Grotesk', sans-serif`
  },
  breakpoints: {
    base: "0em",
    // 0px
    xs: "30em",
    // ~480px
    sm: "48em",
    // ~800px
    md: "70em",
    // ~1120px
    lg: "90em",
    // ~1440px
    xl: "110em",
    // ~1760px
    "2xl": "130em",
    // ~2080px
    "3xl": "160em",
    // ~2560px
    "4xl": "200em" // ~3200px
  },
  styles: {
    global: props => ({
      body: {
        bg: props.colorMode === "dark" ? "black" : "white"
      }
    })
  }
}, withProse({
  baseStyle: {
    p: {
      my: 2,
      letterSpacing: "wide"
    },
    a: {
      textDecoration: "underline"
    }
  }
}));
export let links = () => {
  return [{
    rel: "stylesheet",
    href: appStylesHref
  }, {
    rel: "preconnect",
    href: "https://fonts.googleapis.com"
  }, {
    rel: "preconnect",
    href: "https://fonts.gstatic.com"
  }, {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Rubik:ital,wght@0,300..900;1,300..900&family=Space+Grotesk:wght@300..700&display=swap"
  }];
};
const Document = _s(withEmotionCache(_c = _s(({
  children
}, emotionCache) => {
  _s();
  const serverStyleData = useContext(ServerStyleContext);
  const clientStyleData = useContext(ClientStyleContext);

  // Only executed on client
  useEffect(() => {
    // re-link sheet container
    emotionCache.sheet.container = document.head;
    // re-inject tags
    const tags = emotionCache.sheet.tags;
    emotionCache.sheet.flush();
    tags.forEach(tag => {
      emotionCache.sheet._insertTag(tag);
    });
    // reset cache to reapply global styles
    clientStyleData?.reset();
  }, []);
  return <html lang="en">
        <head>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />

          <title>Truthist - Think for yourself</title>
          <meta name="description" content="AI-powered characters to help you navigate the world" />

          <meta property="og:title" content="Truthist - Think for yourself" />
          <meta property="og:description" content="AI-powered characters to help you navigate the world" />

          <meta property="og:image" content={useColorModeValue("/assets/logo/truthist-logo.png", "/assets/logo/truthist-logo-dark.png")} />

          <Meta />
          <Links />
          {serverStyleData?.map(({
        key,
        ids,
        css
      }) => <style key={key} data-emotion={`${key} ${ids.join(" ")}`} dangerouslySetInnerHTML={{
        __html: css
      }} />)}
        </head>
        <body>
          {children}
          <ScrollRestoration />
          <Scripts />
          <LiveReload />
        </body>
      </html>;
}, "2lyUzYPej4bTy4v84a0KkD7c6bE=", false, function () {
  return [useColorModeValue];
})), "2lyUzYPej4bTy4v84a0KkD7c6bE=", false, function () {
  return [useColorModeValue];
});
_c2 = Document;
const isClient = typeof document !== "undefined";
export function ErrorBoundary() {
  if (isClient) {
    return createElement("html", {
      suppressHydrationWarning: true,
      dangerouslySetInnerHTML: {
        __html: document.getElementsByTagName("html")[0].innerHTML
      }
    });
  }
  return <Document>
      <ChakraProvider theme={theme}>
        <Center h={"100vh"}>
          <VStack spacing={12}>
            <Image src="/assets/error.png" alt="Truthist Error" h={64} w={64} />

            <VStack spacing={4}>
              <TruthistH4>Sorry, something didn't go as expected!</TruthistH4>
            </VStack>
            <TruthistSecondaryButton leftIcon={<FaArrowLeft />} as={Link} to="./..">

              Back
            </TruthistSecondaryButton>
            <TruthistPrimaryButton as={Link} to="/">
              Return Home
            </TruthistPrimaryButton>
          </VStack>
        </Center>
      </ChakraProvider>
    </Document>;
}
_c3 = ErrorBoundary;
export default function App() {
  _s2();
  const toast = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has("success")) {
      let toastMessage = "Success";
      const message = queryParams.get("success") || "";
      if (message != "") {
        const toastMessages = {
          characterCreated: "Created successfully",
          characterDeleted: "Deleted successfully",
          configUpdated: "Config saved successfully",
          fileUploaded: "Uploaded successfully",
          fileRemoved: "Removed successfully"
        };
        toastMessage = toastMessages[message] || "Success";
      }
      toast({
        description: toastMessage,
        status: "success",
        duration: 3000,
        isClosable: true
      });
      navigate(window.location.pathname, {
        replace: true
      });
    } else if (queryParams.has("error")) {
      let toastMessage = "Error";
      const message = queryParams.get("error") || "";
      if (message != "") {
        const toastMessages = {
          generic: "Something went wrong"
        };
        toastMessage = toastMessages[message] || "Error";
      }
      toast({
        description: toastMessage,
        status: "error",
        duration: 3000,
        isClosable: true
      });
    }
  }, [location.pathname, location.search, navigate, toast]);
  return <Document>
      <ChakraProvider theme={theme}>
        <Outlet />
      </ChakraProvider>
    </Document>;
}
_s2(App, "6afpuLOPSqaTw1pUD8KMI9XS8XM=", false, function () {
  return [useToast, useLocation, useNavigate];
});
_c4 = App;
var _c, _c2, _c3, _c4;
$RefreshReg$(_c, "Document$withEmotionCache");
$RefreshReg$(_c2, "Document");
$RefreshReg$(_c3, "ErrorBoundary");
$RefreshReg$(_c4, "App");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;